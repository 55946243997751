import React from "react"
import qs from "qs"
import Container from "@layouts/Container"
import { Box } from "@lib/ui/core"
import Layout from "@layouts/MainLayout"
import SearchInput from "@components/Search/SearchInput"
import SEO from "@components/SEO"

const RebateCalculator = () => {
  const [src, setSrc] = React.useState("rc/rc.html")

  React.useEffect(() => {
    const query = window?.location?.search
    if (query) {
      const queryObj = qs.parse(query.substring(1))
      if (
        "expense" in queryObj &&
        "type" in queryObj &&
        "selectedcard" in queryObj
      ) {
        setSrc(`rc/result.html${query}`)
      }
    }
  }, [])

  return (
    <Layout headerTitle="回贈計數機">
      <SEO
        title="回贈計數機"
        description="輸入信用卡簽卡金額，即刻計到拎返幾多💰現金／✈️里數回贈！仲可以睇埋邊張卡先賺得最多！🤑"
        image="https://www.jetsobee.com/rc/jetsobee-og.jpg"
      />
      <Container>
        <Box
          display="none"
          position="relative"
          mt="-1.5rem"
          mb={{ xs: 2.5, sm: 4 }}
        >
          <SearchInput height="10rem" />
        </Box>
      </Container>
      <div
        dangerouslySetInnerHTML={{
          __html: `<iframe style="width: 100%; height: 90vh; border: none" src="${src}"></iframe>`,
        }}
      />
    </Layout>
  )
}

export default RebateCalculator
